import React, { Suspense } from 'react'
import loadable from '@loadable/component'

function Main() {
  const Content = loadable(() => import('../Content/Content.js'))
  const Canvas3D = loadable(() => import('../Canvas3D/Canvas3D.js'))
  // const BGPlane = loadable(() => import('./Components/Box/Box.js'))
  const Overlay = loadable(() => import('../Overlay/Overlay.js'))

  return (
    <>
      <Overlay />
      <div className="content-wrapper ss preload" id="container" data-scroll-section>
        <Content />
        <Canvas3D />
      </div>
    </>
  )
}

export default Main
