import React from 'react'

import CookieBanner from 'react-cookie-banner'

import useLocoScroll from 'src/_helpers/useLocoScroll.js'
import '@fontsource/inter/200.css' // Weight 400.
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Main from './Components/Main/Main.js'
import ReactGA from 'react-ga'

const history = createBrowserHistory()

function App() {
  useLocoScroll()

  return (
    <Router history={history}>
      <main id="main-container">
        <div className="cookie-banner-wrapper">
          <CookieBanner
            styles={{
              banner: { backgroundColor: '#000' },
              message: { fontWeight: 400 }
            }}
            message="We use Cookies on our Website"
            onAccept={() => {
              ReactGA.initialize('UA-202998544-2', {
                debug: true,
                titleCase: false,
                siteSpeedSampleRate: 100,
                gaOptions: {
                  userId: 123
                }
              })
              ReactGA.event({
                category: 'User',
                action: 'Created an Account'
              })
              history.listen((location, action) => {
                ReactGA.pageview(location.pathname + location.search)
              })
            }}
            buttonMessage="Opt-In"
            cookie="user-has-accepted-cookies"
            disableStyle={true}
          />
        </div>
        <Switch>
          <Route exact path="/" component={Main} />
        </Switch>
      </main>
    </Router>
  )
}

export default App
