
export const CustomUniforms = {
  uResolution: {
    x: window.innerWidth,
    y: window.innerHeight
  },
  uTime: {
    value: 0
  },
  transTime: {
    value: 0
  },
  particles: {
    size: 0.04
  },

  ss: '.ss',
  p: {
    x: 0.,
    y: 0.,
    z: 0.
  },
  ls: null,
  preloader: null
}
